<div [hidden]="noRecords" *ngIf="!newDesign">
    <div class="dv-center">
        <div class="message">
            Mostrar
        </div>
        <div>
            <mat-select [(value)]="defaultSize" class="input-select" (selectionChange)="sendPageZero()">
                <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </div>
        <div>
            <div class="d-i-f message">
                <div class="m-r5">registros</div>
                <div class="m-r5">de</div>
                <div>{{totalRecords}}</div>
            </div>
        </div>

        <div class="d-i-f ml-15" style="width: 280px;">
            <div [ngClass]="{'round-active': arrowFirstActive && arrowFirstLast,
            'round-inactive' : !arrowFirstActive && arrowFirstLast,
            'round-hidden' : !arrowFirstLast }" (click)="first()" matTooltip="Inicio"
                matTooltipPosition="left" [matTooltipDisabled]="!arrowFirstActive && arrowFirstLast">
                <div>
                    <mat-icon class="m-t5">first_page</mat-icon>
                </div>
            </div>
            <div [ngClass]="{'round-active': arrowBackActive && arrowNextBack,
            'round-inactive' : !arrowBackActive && arrowNextBack,
            'round-hidden' : !arrowNextBack }" (click)="back()"  matTooltip="Anterior"
            matTooltipPosition="left" [matTooltipDisabled]="!arrowBackActive && arrowNextBack">
                <div>
                    <mat-icon class="m-t5">chevron_left</mat-icon>
                </div>
            </div>
            <div *ngFor="let page of pages; let i=index" class="d-i-f">
                <div [ngClass]="{'round-active': page.active && !page.hidden,
                'round-inactive' : !page.active && !page.hidden,
                'round-hidden' : page.hidden }" (click)="sendPage(i)">
                    <div>{{i+1}}</div>
                </div>
            </div>
            <div [ngClass]="{'round-active': arrowNextActive && arrowNextBack,
            'round-inactive' : !arrowNextActive && arrowNextBack,
            'round-hidden' : !arrowNextBack }" (click)="next()" matTooltip="Siguiente"
            matTooltipPosition="left" [matTooltipDisabled]="!arrowNextActive && arrowNextBack">
                <div>
                    <mat-icon class="m-t5">chevron_right</mat-icon>
                </div>
            </div>
            <div [ngClass]="{'round-active': arrowLastActive && arrowFirstLast,
            'round-inactive' : !arrowLastActive && arrowFirstLast,
            'round-hidden' : !arrowFirstLast }" (click)="last()"  matTooltip="Final"
            matTooltipPosition="left" [matTooltipDisabled]="!arrowLastActive && arrowFirstLast">
                <div>
                    <mat-icon class="m-t5">last_page</mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="pagesText">
            <div class="d-i-f message">
                <div class="m-r5">{{totalPages}}</div>
                <div>p&aacute;ginas.</div>
            </div>
        </div>

    </div>
</div>
<div [hidden]="noRecords" *ngIf="newDesign" class="d-flex align-items-center">
	<div class="message">
		Mostrar
	</div>
	<div>
		<mat-select [(value)]="defaultSize" class="input-select" (selectionChange)="sendPageZero()">
				<mat-option *ngFor="let option of pageSizeOptions" [value]="option">
						{{option}}
				</mat-option>
		</mat-select>
	</div>
	<div class="d-i-f" style="width: 280px;">
		<div class="arrow me-2" [ngClass]="{'arrow-active': arrowBackActive && arrowNextBack,
			'arrow-inactive ' : !arrowBackActive && arrowNextBack,
			'round-hidden' : !arrowNextBack }" (click)="back()">
				<mat-icon class="me-2 arrow-icon">west</mat-icon>
				Atr&aacute;s
				<!-- <div>
				</div> -->
		</div>
		<div *ngFor="let page of pages; let i=index" class="d-i-f">
				<div [ngClass]="{'round-active': page.active && !page.hidden,
				'round-inactive-arrow' : !page.active && !page.hidden,
				'round-hidden' : page.hidden }" (click)="sendPage(i)">
						<div>{{i+1}}</div>
				</div>
		</div>
		<div class="arrow" [ngClass]="{'arrow-active': arrowNextActive && arrowNextBack,
		'arrow-inactive ' : !arrowNextActive && arrowNextBack,
		'round-hidden' : !arrowNextBack }" (click)="next()">
			Siguiente
			<mat-icon class="ms-2 arrow-icon">east</mat-icon>
			<!-- <div>
			</div> -->
		</div>
	</div>
</div>
