import { createAction, props } from "@ngrx/store";
import { MessageObject } from "./models/message.model";
import { UserList } from "./models/segmentacion.model";
import { Frequency } from "./models/frequency.model";

export const updateMessage = createAction('[NOTIFICATION] Set message', props<{message: MessageObject}>());
export const setUsers = createAction('[NOTIFICATION] Set users', props<{users: UserList[]}>());
export const setUsersCount = createAction('[NOTIFICATION] Set users count', props<{count: number}>());
export const setType = createAction('[NOTIFICATION] Set type segmentation', props<{typeS: number}>());
export const setFrecuency = createAction('[NOTIFICATION] Set frequency', props<{frecuency: Frequency}>());
export const setReasign = createAction('[NOTIFICATION] Set Reasign', props<{reasign: boolean}>());
export const updateFrecuency = createAction('[NOTIFICATION] Update frequency', props<{value: string | boolean | number, key: string}>());
export const updateMessageKey = createAction('[NOTIFICATION] Update message key', props<{value: string | boolean | number, key: string}>());
export const clearNotification = createAction('[NOTIFICATION] Clear notification');