import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'svg-crown',
  template: `
    <svg [attr.width]="size" [attr.height]="size" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.7101 16.0218L22.9418 13.7903C23.0653 12.5996 23.1469 11.8135 23.0829 11.318L23.1053 11.3182C24.1516 11.3182 25 10.439 25 9.35456C25 8.27008 24.1516 7.39097 23.1053 7.39097C22.059 7.39097 21.2105 8.27008 21.2105 9.35456C21.2105 9.84503 21.3841 10.2935 21.6709 10.6376C21.259 10.9012 20.7207 11.457 19.9101 12.2936C19.2857 12.9383 18.9735 13.2605 18.6252 13.3105C18.4322 13.3381 18.2356 13.3098 18.0574 13.2284C17.7358 13.0815 17.5213 12.6832 17.0924 11.8863L14.8322 7.68602C14.5676 7.19438 14.3461 6.78301 14.1465 6.4519C14.9655 6.01885 15.5263 5.13636 15.5263 4.11819C15.5263 2.67225 14.3953 1.5 13 1.5C11.6047 1.5 10.4737 2.67225 10.4737 4.11819C10.4737 5.13636 11.0345 6.01885 11.8535 6.4519C11.6539 6.78301 11.4324 7.19438 11.1678 7.68602L8.90745 11.8863C8.47857 12.6832 8.26418 13.0815 7.94255 13.2284C7.76439 13.3098 7.56775 13.3381 7.3748 13.3105C7.02653 13.2605 6.71429 12.9383 6.0899 12.2936C5.27939 11.457 4.74092 10.9012 4.32908 10.6376C4.61592 10.2935 4.78949 9.84503 4.78949 9.35456C4.78949 8.27008 3.94122 7.39097 2.89469 7.39097C1.84816 7.39097 1 8.27008 1 9.35456C1 10.439 1.84827 11.3182 2.89469 11.3182L2.91714 11.318C2.85306 11.8135 2.93469 12.5996 3.05827 13.7903L3.2899 16.0218C3.41837 17.2605 3.52531 18.4391 3.65622 19.5H22.3437C22.4747 18.4391 22.5815 17.2605 22.7101 16.0218Z" [attr.stroke]="color" stroke-width="1.5"/>
      <path d="M11.6308 24.5H14.3692C17.9385 24.5 19.723 24.5 20.9138 23.2683C21.4335 22.7306 21.7625 21.7613 22 20.5H4C4.2374 21.7613 4.5665 22.7306 5.08624 23.2683C6.27699 24.5 8.0616 24.5 11.6308 24.5Z" [attr.stroke]="color" stroke-width="1.5"/>
    </svg>
  `,
  styles: [
  ]
})
export class CrownComponent implements OnInit {

  @Input() color: string = '#00A3B3';
  @Input() size: number = 20;

  constructor() { }

  ngOnInit(): void {
  }

}
