import { NgModule } from '@angular/core';

import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';
import {CdkTableModule} from '@angular/cdk/table';
import {
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatExpansionModule} from '@angular/material/expansion';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
	imports: [
		MatButtonModule,
		MatSliderModule,
		MatInputModule,
		MatSelectModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		FormsModule,
		ReactiveFormsModule,
		MatSortModule,
		MatTooltipModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		MatMenuModule,
		MatFormFieldModule,
		MatMenuModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		MatProgressSpinnerModule,
		MatCardModule,
		MatProgressBarModule,
		MatTabsModule,
		MatChipsModule,
		CdkTableModule,
		MatExpansionModule,
		DragDropModule
	],
	exports: [
		MatButtonModule,
		MatSliderModule,
		MatInputModule,
		MatSelectModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		FormsModule,
		ReactiveFormsModule,
		MatSortModule,
		MatTooltipModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatMenuModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		MatMenuModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		MatCardModule,
		MatProgressBarModule,
		MatTabsModule,
		MatChipsModule,
		CdkTableModule,
		MatExpansionModule,
		DragDropModule
	],
	providers: [
		MatDatepickerModule
	]
})

export class MaterialModule { }
