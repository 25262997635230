import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'money-svg',
  template: `
    <svg [attr.width]="size" [attr.height]="size" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.27344 1V13" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 3.18164H2.90909C2.40277 3.18164 1.91718 3.38278 1.55916 3.7408C1.20114 4.09882 1 4.58441 1 5.09073C1 5.59705 1.20114 6.08264 1.55916 6.44066C1.91718 6.79869 2.40277 6.99982 2.90909 6.99982H5.63636C6.14269 6.99982 6.62827 7.20096 6.9863 7.55898C7.34432 7.91701 7.54545 8.40259 7.54545 8.90891C7.54545 9.41524 7.34432 9.90082 6.9863 10.2588C6.62827 10.6169 6.14269 10.818 5.63636 10.818H1" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  `,
  styles: [
  ]
})
export class MoneySvgComponent implements OnInit {

  @Input() color: string = '#04BB00';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit(): void {
  }

}
