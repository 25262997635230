<div class="d-flex align-items-center">
  <mat-icon class="filter-icon" *ngIf="icon">{{icon}}</mat-icon>
  <img *ngIf="image" [src]="image" width="24" height="24" />
  <p-dropdown #dropdown [options]="optionsDropdown" emptyMessage="No se encontraron resultados." optionLabel="name" [disabled]="disabled"
    (onChange)="emitValue($event.value)"
    [placeholder]="name" [(ngModel)]="optionSelected" class="custom-dropdown"
  >
    <ng-template pTemplate="selectedItem">
      <div class="filter-name">
        {{name}}
      </div>
    </ng-template>
    <!-- <ng-template let-mission pTemplate="itemUser">
      <div class="mission-item">
        <div [style.color]="mission.id === 0 ? 'var(--blue-p)' : '#000000'">{{mission.complete}}</div>
      </div>
    </ng-template> -->
  </p-dropdown>

</div>