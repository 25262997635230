<div class="counter" [ngClass]="{'active': data.active}" [style.backgroundColor]="data.active ? data.color : '#FFF'" (click)="selectCount()">
  <div class="counter-icon" [style.backgroundColor]="data.active ? data.colorIcon : data.color">
    <img [src]="data.icon" alt="icono"/>
  </div>
  <div class="d-flex flex-column justify-content-center ms-2">
    <h4 class="fw-500 m-0">{{data.title}}</h4>
    <h5 class="m-0 lh-1">{{data.subtitle}}</h5>
  </div>
  <span class="counter-number">{{data.count}}</span>

</div>
