import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SecurityService } from '../../../security/security.service';
import { HomeService } from '../../services/home.service';
import { InfoModalService } from '../../../shared/services/modals/info-modal.service';
import { delay } from 'rxjs/operators';
import { Module } from '../../modules/response.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { USER } from '../../../shared/models/user';
import { eventTypes } from 'src/app/shared/models/event.model';
import { EventCollector } from 'src/app/utils/eventCollector';
import {Store} from "@ngrx/store";
import {AppState} from "../../../store/app.reducer";
import {AuthStorage, NewUser, OperationTypes} from "../../../auth/models/login.model";
import {logOut, setOperationType, setUser} from "../../../auth/auth.actions";
import {LoginService} from "../../../auth/services/login.service";
import {loadProject, setProject, unsetProject} from "../../../project-management/project.actions";
import {ProjectState} from "../../../project-management/project.reducer";
import { selectLogo } from 'src/app/project-management/project.selectors';
import { Generic } from 'src/app/utils/generic';
import { RouterService } from 'src/app/shared/services/router.service';

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.css'],
})
export class ContainerComponent implements OnInit, OnDestroy {
	baseRouting: string = '/admin';
	numNotifications: string = '0';
	hideNotification = true;
	userInfo: any = {};
	isExpandedMenu = true;
	isExpandedIcons = false;
	attempts: any = {};
	activeParentIcon: boolean = false;
	selectedIndex: any;
	iconSelected: any;
	isHover: boolean = false;
	optionSelected: Module;
	inSubMenu = false;
	inIconMenu = false;
	eventCollectorObj: any = {
		2: ['click_crearYAsignarDiseñaTuAccionOExamen', 'Crear y Asignar'],
		3: ['click_crearYAsignarProgramaTuAccion', 'Crear y Asignar'],
		4: ['click_crearYAsignarMisiones', 'Crear y Asignar'],
		5: ['click_crearYAsignarMisionesAsignacionMasiva', 'Crear y Asignar'],
		6: ['click_crearYAsignarComunicados', 'Crear y Asignar'],
		7: ['click_crearYAsignarSecciones', 'Crear y Asignar'],
		26: ['click_crearYAsignarCursos', 'Crear y Asignar'],
		9: ['click_reportesCheckInCheckOut', 'Reportes'],
		10: ['click_reportesAcciones', 'Reportes'],
		11: ['click_reportesMisiones', 'Reportes'],
		12: ['click_reportesComunicados', 'Reportes'],
		13: ['click_reportesSellosDigitales', 'Reportes'],
		14: ['click_reportesFacturasPago', 'Reportes'],
		15: ['click_reportesRetenciones', 'Reportes'],
		16: ['click_reportesInfomacionDeTrader', 'Reportes'],
		17: ['click_reportesAvanceDeVentas', 'Reportes'],
		27: ['click_reportesCursos', 'Reportes'],
		19: ['click_pagarATradersOrdenDePago', 'Pagar a Traders'],
		20: ['click_pagarATradersDispersarConciliar', 'Pagar a Traders'],
		21: ['click_pagarATradersRetenciones', 'Pagar a Traders'],
		23: ['click_configuracionGestionDeModulos', 'Configuración'],
		24: ['click_gestionDeUsuarios', 'Gestión de Usuarios'],
		25: ['click_avanceDeVentas', 'Avance de Ventas'],
	};

	@ViewChild('sidenav') sidenav: ElementRef;
	currentUser: USER; // OLD LOGIN
	newUser: NewUser; // NEW LOGIN
	operationType: OperationTypes;
	operationsTypes: OperationTypes[] = [];
	projectSelected: ProjectState | null;
	allClients: boolean = false;
	@ViewChild('menuOptionsDiv') menuOptionsDiv: ElementRef<HTMLDivElement>;
	@ViewChild('menuOptionIcon') menuOptionIcon: MatIcon;
	menuOptions: Module[] = [];

	closeSubject = new Subject();
	subsUnsuscribe: Subscription;
	stopSub: Subscription;
	stopSubOperation: Subscription;
	interval: NodeJS.Timeout;
	logo: Observable<string>;
	superAdmin = false;
	notShownAllClient = [38, 18, 22, 34];

	constructor(
		private router: Router,
		private loader: NgxSpinnerService,
		private security: SecurityService,
		private infoModalService: InfoModalService,
		private homeService: HomeService,
		private loginService: LoginService,
		private eventCollector: EventCollector,
		private store: Store<AppState>,
		private routerService: RouterService
	) {}

	ngOnInit(): void {
		const info = localStorage.getItem('auth');
		if (info) {
			this.superAdmin = true;
			const auth: AuthStorage = JSON.parse(atob(info));
			this.store.dispatch(setUser({user: auth.user}));
			this.newUser = auth.user;
			// this.store.dispatch(setOperationType({operation: auth.operationType!}));
			if (auth.operationType?.id === 2) {
				this.setThema('orange')
			} else {
				this.setThema('blue')
			}
			if (!localStorage.getItem('operationTypes')) {
				this.getOperationTypes(this.newUser.idUser);
			} else {
				this.operationsTypes = JSON.parse(atob(localStorage.getItem('operationTypes')!));
				const selectedOperation: OperationTypes = this.operationsTypes.find(item => item.id === auth.operationType?.id)!;
				this.store.dispatch(setOperationType({operation: selectedOperation}));
			}
			this.logo = this.store.select(selectLogo);
			this.interval = setInterval(() => this.checkModules(), 300000);
			this.stopSubOperation = this.homeService.updateOperation.subscribe(() => {
				this.getOperationTypes(this.newUser.idUser);
			})
		}
		if (localStorage.getItem('project')) {
			const project = JSON.parse(atob(localStorage.getItem('project')!));
			this.store.dispatch(loadProject({project}));
		}
		this.stopSub = this.store.subscribe(({auth, project}) => {
			if (auth.operationType) {
				this.operationType = auth.operationType;
				const navBar = document.querySelector('mat-sidenav .mat-drawer-inner-container') as HTMLDivElement;
				navBar.style.backgroundColor = auth.operationType.color;
			}
			if (project.projectId > -1) {
				this.projectSelected = project;
			} else {
				this.projectSelected = null;
			}
			this.allClients = project.allClient;
			if (this.allClients) {
				this.menuOptions = this.menuOptions.filter(m => !this.notShownAllClient.includes(m.moduleId));
			} else {
				let menu = localStorage.getItem('modules');
				if (menu && (atob(menu) != '' || atob(menu) != '[]')) {
					this.menuOptions = JSON.parse(atob(menu));
				} 
			}
		});
		let user = localStorage.getItem('user');
		if (user) {
			this.currentUser = JSON.parse(atob(user));
		}
		let menu = localStorage.getItem('modules');

		if (menu && (atob(menu) != '' || atob(menu) != '[]')) {
			this.menuOptions = JSON.parse(atob(menu));
			if (this.allClients) {
				this.menuOptions = this.menuOptions.filter(m => !this.notShownAllClient.includes(m.moduleId));
			}
			console.log(this.menuOptions);
		} else {
			if (this.newUser) {
				this.getNewMenu();
			} else {
				this.getMenuOptions();
			}
		}
		const idRol = 2;
		// this.buildMenu(idRol);
		// this.sidenav.open();
		this.subsUnsuscribe = this.closeSubject
			.pipe(delay(1000))
			.subscribe((value) => {
				if (!this.inSubMenu && !this.inIconMenu) {
					this.closeSubMenu();
				}
			});
	}

	async checkModules () {
		const menu = await this.getNewMenu(false);
		const menuString = JSON.stringify(menu);
		const menuSaved = atob(localStorage.getItem('modules') || '');
		if (menu.length === 0) {
			await this.infoModalService.openInfoModal({title: '¡Ups!', text: 'Este perfil no cuenta con un rol. Contacta a soporte para habilitar los modúlos.', type: 'warning'})
			this.logout();
		}
		if (menuString !== menuSaved) {
			await this.infoModalService.openInfoModal({title: '¡Ups!', text: 'Tu perfil tuvo cambios es necesario que inicies sesión nuevamente de clic en aceptar.', type: 'warning'})
			this.logout();
		}
	}

	eventSelected(m: Module) {
		if (!this.projectSelected) {
			return;
		}
		const pageSelected = this.eventCollectorObj[m.moduleId]
			? this.eventCollectorObj[m.moduleId]
			: '';
		if (pageSelected != '') {
			this.eventCollector.event({
				type: eventTypes.click,
				module: pageSelected[1],
				class: 'ContainerComponent',
				method: 'eventSelected()',
				data: '',
				name: pageSelected[0],
			});
		}
	}

	ngOnDestroy() {
		this.subsUnsuscribe.unsubscribe();
		this.stopSub.unsubscribe();
		this.stopSubOperation?.unsubscribe();
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	// buildMenu(idRol: number) {
	// 	if (idRol === 1) {
	// 		this.menuOptions = routesAvailables.user;
	// 	} else {
	// 		this.menuOptions = routesAvailables.admin;
	// 	}
	// }

	async getMenuOptions() {
		try {
			this.loader.show();
			const data = {
				idUser: this.currentUser.idUser,
				idRol: this.currentUser.rol,
				idCompany: 0,
			};

			const encryptedResponse: any = await this.security.encrypt({ data });
			const menuResponse: any = await this.homeService.getMenuOptions({
				data: encryptedResponse.body.data,
			});
			const decryptedResponse = await this.security.decrypt({
				data: menuResponse.data,
			});
			console.log('decryptedResponseMenú ====> ', decryptedResponse);
			this.loader.hide();
			if (
				decryptedResponse.body.data.success &&
				decryptedResponse.body.data.items
			) {
				if (
					decryptedResponse.body.data.items[0].modules &&
					decryptedResponse.body.data.items[0].modules.length > 0
				) {
					this.menuOptions = decryptedResponse.body.data.items[0].modules || [];
					for (const option of this.menuOptions) {
						if (option.modules.length > 0) {
							option.parent = true;
						} else {
							option.parent = false;
						}
					}
					console.log('\nOpciones de Menú = ', this.menuOptions);
					localStorage.setItem('modules', btoa(JSON.stringify(this.menuOptions)));
				} else {
					this.infoModal(
						'Permisos no disponibles',
						'No se pudo obtener los permisos del menú.',
						'warning'
					);
				}
			} else {
				this.infoModal(
					'Permisos no disponibles',
					'No se pudo obtener los permisos del menú.',
					'warning'
				);
			}
		} catch (e) {
			console.error(e);
			this.loader.hide();
			this.infoModalService.openGenericInfo();
		}
	}

	async getNewMenu (loader = true): Promise<Module[]> {
		try {
			if (loader) {
				this.loader.show();
			}
			const menuResponse: any = await this.homeService.getNewMenu(this.newUser.idUser);
			const decryptedResponse = await this.security.decrypt({data: menuResponse.data});
			if (loader) {
				this.loader.hide();
			}
			if (decryptedResponse.body.data.success) {
				const response = decryptedResponse.body.data.items[0].modules || []
				if (loader && response.length === 0) {
					await this.infoModalService.openInfoModal({title: '¡Ups!', text: 'Este perfil no cuenta con un rol. Contacta a soporte para habilitar los modúlos.', type: 'warning'});
					this.logout();
					return [];
				}
				const menu = response.map((item: any) => {
					return {
						...item,
						parent: item.modules.length > 0
					}
				});
				if (loader) {
					this.menuOptions = menu;
					const modulesRoute = Generic.flat(this.menuOptions, 'modules').filter(item => item.route !== '').map(item => item.route.split('/').slice(0, 3).join('/'));
					localStorage.setItem('modules', btoa(JSON.stringify(this.menuOptions)));
					localStorage.setItem('routes', btoa(JSON.stringify([...new Set(modulesRoute)])));
				}
				return menu;
			} else {
				if (decryptedResponse.body.data.message.id === 19) {
					await this.infoModalService.openInfoModal({title: '¡Ups!', text: 'Este perfil no cuenta con un rol. Contacta a soporte para habilitar los modúlos.', type: 'warning'});
					this.logout();
				} else {
					this.infoModal(
						'Permisos no disponibles',
						'No se pudo obtener los permisos del menú.',
						'warning'
					);
				}
				return [];
			}
		} catch (e) {
			this.loader.hide();
			this.infoModalService.openGenericInfo();
			return [];
		}
	}

	async getOperationTypes (userId: number, dispatch = true): Promise<void> {
		try {
			this.loader.show();
			const encryptedResponse: any = await this.loginService.getOperationTypes(userId);
			const decryptedResponse = await this.security.decrypt({data: encryptedResponse.data});
			this.loader.hide();
			if (decryptedResponse.body.data.success) {
				this.operationsTypes = decryptedResponse.body.data.items[0].operationTypes;
				if (dispatch) {
					const selectedOperation: OperationTypes = this.operationsTypes.find(item => item.id === this.operationType.id)!;
					this.store.dispatch(setOperationType({operation: selectedOperation}));
				}
				localStorage.setItem('operationTypes', btoa(JSON.stringify(this.operationsTypes)));
			} else {
				this.infoModalService.openGenericInfo();
			}
		} catch (e) {
			this.loader.hide();
			this.infoModalService.openGenericInfo();
		}
	}

	navigate (module: Module) {
		if (this.newUser && !this.projectSelected && module.moduleId !== 28 && !this.allClients) { return; }
		if (!module.route) return;
		if ((this.router.url.includes('module-management') || this.router.url.includes('users/register')) && this.superAdmin) {
			this.routerService.existOfModule.next(module.route);
			return;
		}
		this.router.navigate([module.route]);
	}

	openMenu () {
		// document.querySelectorAll('.mat-menu-panel.options-menu')
		if (!this.newUser) { return; }
		const menu = document.querySelectorAll('.mat-menu-panel.options-menu') as NodeListOf<HTMLDivElement>;
		menu.forEach(item => {
			item.style.border = `2px solid ${this.operationType.color}`;
		})
		// menu.style.border = `2px solid ${this.operationType.color}`;
	}

	setThema(t: string) {
    document.documentElement.className = t;
  }

	async changeAccount (idOperationType: number) {
		await this.getOperationTypes(this.newUser.idUser, false);
		const selectedOperation: OperationTypes | undefined = this.operationsTypes.find(item => item.id === idOperationType);
		if (!selectedOperation) return;
		this.store.dispatch(unsetProject());
		localStorage.removeItem('project')
		this.store.dispatch(setOperationType({operation: selectedOperation}));
		const info = {
			user: this.newUser,
			operationType: selectedOperation
		}
		if (selectedOperation.id === 2) {
			this.setThema('orange')
		} else {
			this.setThema('blue')
		}
		localStorage.setItem('auth', btoa(JSON.stringify(info)));
		this.router.navigate(['/admin/home'])
	}

	logout() {
		localStorage.clear();
		this.store.dispatch(logOut());
		this.store.dispatch(unsetProject());
		this.router.navigate(['/auth/login']);
	}

	isActiveRoute(actualRoute: string): boolean {
		if (actualRoute) {
			let r = actualRoute.split('/');
			return this.router.url.includes(`/${r[1]}/${r[2]}/`);
		} else {
			return false;
		}
	}

	isActiveSubRoute(suboption: any, parent: any) {
		this.selectedIndex = suboption;
		this.iconSelected = parent;
		//event.target.addClassName('is-active')
		/*this.activeParentIcon = false;
		if(actualRoute) {
			let r = actualRoute.split('/');
			this.activeParentIcon = this.router.url.includes(`/${r[1]}/${r[2]}/`); // this.router.url.includes(`/${r[r.length-1]}`);
		}
		return this.activeParentIcon;*/
	}

	changeMenu() {
		if (this.isExpandedMenu) {
			this.isExpandedMenu = false;
			this.isExpandedIcons = true;
		} else {
			this.isExpandedIcons = false;
			this.isExpandedMenu = true;
		}
	}

	infoModal(title: string, text: string, type: string) {
		this.infoModalService.openInfoModal({
			title: title,
			text: text,
			type: type,
			cancelButton: false,
			confirmButton: true,
		});
	}

	isParent() {
		this.iconSelected = '';
		this.selectedIndex = '';
	}

	isOnHover(option: Module) {
		if (!option.parent || (this.newUser && !this.projectSelected && !this.allClients)) {
			return;
		}
		this.optionSelected = option;
		this.isHover = true;
		this.inIconMenu = true;
		const div = document.getElementById(`link-${option.moduleId}`)!;
		const { top, height } = div.getBoundingClientRect();
		let newHeight;
		if (this.optionSelected.modules.length > 4) {
			newHeight = height / 2;
		} else {
			newHeight = 0;
		}
		this.menuOptionsDiv.nativeElement.style.top = top - newHeight + 'px';
		this.menuOptionsDiv.nativeElement.style.display = 'flex';
		this.menuOptionIcon._elementRef.nativeElement.style.top = top + 10 + 'px';
		this.menuOptionIcon._elementRef.nativeElement.style.display = 'initial';
		this.closeMenu();
	}

	closeMenu() {
		this.closeSubject.next();
	}

	closeSubMenu() {
		this.isHover = false;
		this.menuOptionsDiv.nativeElement.style.display = 'none';
		this.menuOptionIcon._elementRef.nativeElement.style.display = 'none';
		this.inSubMenu = false;
		this.inIconMenu = false;
	}

	clearLocalStorage(m: any) {
		if (m.moduleId == 19) {
			localStorage.removeItem('company');
			location.hash == '#' + m.route && location.reload();
		}
	}
}
