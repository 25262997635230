import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginatorLocalService } from '../../services/paginator-local.service';
import { IPaginator } from './paginator.model';

@Component({
  selector: 'app-paginator-local',
  templateUrl: './paginator-local.component.html',
  styleUrls: ['./paginator-local.component.css', '../paginator/paginator.component.css']
})
export class PaginatorLocalComponent implements OnInit, OnDestroy {

  @Input() defaultSize: number;
  @Input() pageSizeOptions: number[];
	@Input() arrowFirstLast: boolean = false;
	@Input() pagesText: boolean = false;
	@Input() arrowNextBack: boolean = false;
	@Input() newDesign: boolean = false;

  @Output() changePage = new EventEmitter<number>();
	@Output() dataDispatched = new EventEmitter<any[]>();

  data: any[] = [];
  dataPage: any[] = [];
  totalRecords: number = 0;
  dataPageSuscription: Subscription;

  noRecords: boolean = true;
  pages: IPaginator[] = [];
  activePage: number;
  arrowNextActive: boolean = false;
  arrowBackActive: boolean = false;
  indexPage: number = 0;
  totalPages: number = 0;
  arrowFirstActive: boolean = false;
  arrowLastActive: boolean = false;


  constructor(private paginatorService: PaginatorLocalService) { }

  ngOnInit(): void {

    this.dataPageSuscription = this.paginatorService.data$.subscribe(
      response => {
        if (response !== undefined && response !== null && response.length > 0) {
          this.data = response;
          this.totalRecords = this.data.length;
          this.configPages();
          this.configData();
        }
      }, error => {
        console.log(error)
      }
    )
  }

  configPages() {
    this.noRecords = false;
    this.pages = [];
    for (let i = 0, limit = this.totalRecords / this.defaultSize; i < limit; i++) {
      const obj: IPaginator = {
        active: false,
        hidden: true
      }
      this.pages[i] = obj;
    }

    this.totalPages = this.pages.length;
    this.arrowLastActive = this.arrowNextActive = this.totalPages > 1

    this.pageZero();
    this.activePage = 0;
  }

  configData() {
    this.dataPage = [];
    for (let i = 0, limit = this.data.length; i < limit; i += this.defaultSize) {
      let array = this.data.slice(i, i + this.defaultSize);
      this.dataPage.push(array);
    }
    this.dispachedData(0);
  }

  dispachedData(page:number){
    this.dataDispatched.emit(this.dataPage[page]);
  }

  ngOnDestroy(): void {
    this.dataPageSuscription.unsubscribe();
  }

  pageZero() {
    this.pages[0].active = true;
    this.pages[0].hidden = false;
    if (this.totalPages >= 2) {
      this.pages[1].active = false;
      this.pages[1].hidden = false;
    }
    if (this.totalPages >= 3) {
      this.pages[2].active = false;
      this.pages[2].hidden = false;
    }
  }

  sendPage(page: number) {
    this.indexPage = page;
    if (page !== this.activePage) {
      this.inactiveAll();
      this.activePage = page;
      this.pages[page].active = true;
      this.pages[page].hidden = false;
      if (page > 0) {
        this.pages[page - 1].active = false;
        this.pages[page - 1].hidden = false;
        if ((page + 1) < this.totalPages) {
          this.pages[page + 1].active = false;
          this.pages[page + 1].hidden = false;
        }
      } else {
        this.pageZero();
      }
      this.arrowFirstActive = this.arrowBackActive = (this.totalPages > 1 && this.indexPage > 0);
      this.arrowLastActive = this.arrowNextActive = (this.indexPage + 1) < this.totalPages;
      this.dispachedData(page);
    }
  }

  inactiveAll() {
    this.pages.forEach(element => {
      element.hidden = true;
      element.active = false;
    })
  }

  sendPageZero() {
    this.changePage.emit(this.defaultSize);
  }

  back() {
    if ((this.indexPage - 1) >= 0) {
      this.sendPage(--this.indexPage);
    }
  }

  next() {
    if ((this.indexPage + 1) < this.totalPages) {
      this.sendPage(++this.indexPage);
    }
  }

  first() {
    this.sendPage(0);
  }

  last() {
    this.sendPage(this.totalPages - 1);
  }

}
