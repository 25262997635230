import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InfoModal } from '../../models/modals/info-modal';
import { InfoModalComponent } from '../../pages/info-modal/info-modal.component';

@Injectable({
  providedIn: 'root'
})
export class InfoModalService {
  private modalActive: NgbModalRef;
  private infoModalOptions: InfoModal;
  private modals: any[] = [];

  constructor(private modalservice: NgbModal) {
    this.infoModalOptions = {
      title: '',
      text: '',
      cancelButton: false,
      confirmButton: true,
      textCancelButton: 'Cancelar',
      textConfirmButton: 'Aceptar',
      showCloseButton: true,
			justTitle: false,
      footer: false,
      titleSize: '22px',
      question: false,
    }
  }

  openInfoModal(modalConfig: InfoModal, backdrop: boolean | 'static' = 'static') {
		if(!modalConfig.textConfirmButton) {
			this.infoModalOptions.textConfirmButton = 'Aceptar';
			this.infoModalOptions.textCancelButton =  'Cancelar';
		}
    if (!modalConfig.justTitle) {
      modalConfig.justTitle = false;
    }
    const options = {...this.infoModalOptions, ...modalConfig};
    this.modalActive = this.modalservice.open(InfoModalComponent,  {centered: true, backdrop: backdrop, keyboard: false, modalDialogClass: modalConfig.question ? 'info-modal question' : 'info-modal' });
    this.modalActive.componentInstance.data = options;
		this.modals.push(this.modalActive);
    return new Promise((resolve, reject) => {
      this.modalActive.componentInstance.response.subscribe((resp: any) => {
        this.closeInfoModal();
        resolve(resp);
      });
    });
  }

  closeInfoModal() {
    this.modalActive.close();
		if(this.modals.length > 1) {
			for (const m of this.modals) {
				m.close();
			}
		}
  }

  openGenericInfo(message: string = '') {
    this.openInfoModal({
      title: 'Aviso',
      text: `Servicio temporalmente fuera de servicio. Intenta mas tarde${message}.`,
			type: 'warning'
    });
  }

	openNoInternet () {
		this.openInfoModal({
			title: 'Aviso',
			text: `Servicio temporalmente no disponible, verifica tu conexión a internet e intenta más tarde.`,
			type: 'warning',
			textConfirmButton: 'Ok'
		});
	}

  isOpen () {
    return this.modalservice.hasOpenModals()
  }
}