import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginatorLocalService {
  
  data$ = new EventEmitter<any[]>();
  constructor() { }
}
