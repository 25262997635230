import { Directive, ElementRef, HostListener } from '@angular/core';
import { REGULAR_EXPRESSIONS } from '../../constants/constants';

@Directive({
  selector: '[inputSanitize]',
})
export class SanitizeInputDirective {

  element: HTMLInputElement;

  constructor(private el: ElementRef<HTMLInputElement>) { 
    this.element = el.nativeElement;
  }

  @HostListener('input', ['$event']) onInput (event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.replaceValue(value);
  }

  @HostListener('change', ['$event']) onChange (event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.replaceValue(value);
  }

  @HostListener('blur', ['$event']) onBlur (event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.replaceValue(value);
    this.element.dispatchEvent(new Event('input',{
      bubbles: true,
      cancelable: true,
    }));
  }

  replaceValue (value: string) {
    this.element.value = value.replace(REGULAR_EXPRESSIONS.ZERO_WIDTH, '');
  }
}
