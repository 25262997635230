import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

	@Output() fileDropped = new EventEmitter<any>();

  constructor() { }

	// Dragover Event
	@HostListener('dragover', ['$event']) dragOver(event: any) {
		event.preventDefault();
		event.stopPropagation();
	}
	// Dragleave Event
	@HostListener('dragleave', ['$event']) public dragLeave(event: any) {
		event.preventDefault();
		event.stopPropagation();
	}
	// Drop Event
	@HostListener('drop', ['$event']) public drop(event: any) {
		event.preventDefault();
		event.stopPropagation();
		const files = event.dataTransfer.files;
		if (files.length > 0) {
			this.fileDropped.emit(files);
		}
	}

}
