import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {from, Observable, ObservableInput} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SecurityService} from "../security/security.service";
import {environment} from "../../environments/environment";

// import {services} from '@sistemas-upax/utils';


@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  constructor(private security: SecurityService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (request.headers.get("skip")) {
			request = request.clone({
				headers: request.headers.delete('skip')
			});
			return next.handle(request);
		}
    if (request.method === 'GET') {
      return next.handle(request).pipe(map((response) => {
				/*if (response instanceof HttpResponse) {
					const decoder = from(this.security.decrypt2(response.body)).toPromise().then(((decode: any) => {
						return decode.body;
					}));
					return response.clone({
						body: decoder
					});
				}*/

				//--old
        /*if (response instanceof HttpResponse) {
          const decoder = from(services.decrypt(response.body));
          return response.clone({
            body: decoder
          });
        }*/
        return response;
      }));
    } else {
			/*const encoder: any = from(this.security.encrypt2(request.body));
			return encoder.pipe(mergeMap((encoded: any) => {
				request = request.clone({
					body: {data: encoded.body.data}
				});
				return next.handle(request).pipe(map((response) => {
					if (response instanceof HttpResponse) {
						let decoder: any = {};
						console.log('response ---> ', response);
						if (response.body && response.body.body) {
							decoder = from(this.security.decrypt2({data: response.body.body.data})).toPromise().then(((decode: any) => {
								return decode.body;
							}));
						} else {
							decoder = from(this.security.decrypt2({data: response.body.data})).toPromise().then(((decode: any) => {
								return decode.body;
							}));
						}
						console.log(decoder);
						return response.clone({
							body: decoder
						});
					}
					return response;
				}));
			})); */

			//--old
      // const encoder = from(services.encrypt(request.body));
      /* return encoder.pipe(mergeMap((encoded: any) => {
        request = request.clone({
          body: encoded
        });
        return next.handle(request).pipe(map((response) => {
          if (response instanceof HttpResponse) {
            let decoder: any = {};

            if (response.body && response.body.body) {
              decoder = from(services.decrypt(response.body.body));
            } else {
              decoder = from(services.decrypt(response.body));
            }

            return response.clone({
              body: decoder
            });
          }
          return response;
        }));
      })); */



			// call endpoint /health in courses
			if (!request.url.includes('encrypt') && !request.url.includes('decrypt')) {
				let isCourse: boolean = false;
				let api = {
					course:'/cu-course-settings',
					lesson: '/cu-course-lesson',
					test:  '/cu-test'
				};
				let urlHealt = environment.API.BASE_API;
				if (request.url.includes(api.course)) {
					isCourse = true;
					urlHealt += api.course;
				}
				if (request.url.includes(api.lesson)) {
					isCourse = true;
					urlHealt += api.lesson;
				}
				if (request.url.includes(api.test)) {
					isCourse = true;
					urlHealt += api.test;
				}
				urlHealt += '/health';

				if (isCourse) {
					// console.log('urlHealt ---> ', urlHealt);
					const health = from(this.security.healtCheck(urlHealt));
					return health.pipe(mergeMap((respHealth: any) => {
						return next.handle(request).pipe(map((response) => { return response; }));
					}));
				} else {
					return next.handle(request).pipe(map((response) => { return response; }));
				}

			} else {
				return next.handle(request).pipe(map((response) => { return response; }));
			}

    }
  }
}
