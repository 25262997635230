import { Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appDropdownInfinite]'
})
export class DropdownInfiniteDirective implements OnDestroy {

  @Output() infiniteScroll = new EventEmitter();
  @Input() complete: boolean = false;
  debounceTime = 150;
  panel: Element;
  startEvent: number;
  private destroyed$ = new Subject<boolean>();

  constructor(private dropdown: Dropdown, private ngZone: NgZone) {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  @HostListener('onShow') show () {
    console.log('show', this.dropdown);
    this.panel = this.dropdown.overlay.children[0];
    this.registerScrollEvent()
  }

  @HostListener('onHide') close () {
    console.log('close');
  }

  registerScrollEvent () {
    fromEvent(this.panel, 'scroll').pipe(
      takeUntil(this.destroyed$),
      debounceTime(this.debounceTime),
      tap((event) => {
        this.handleScrollEvent(event);
      })
    ).subscribe();
  }

  handleScrollEvent(event: any) {
    this.ngZone.runOutsideAngular(() => {
      if (this.complete) {
        return;
      }
      // const countOfRenderedOptions = this.matSelect.options.length;
      // const infiniteScrollDistance = this.singleOptionHeight * countOfRenderedOptions;
      // const threshold = this.thrPc !== 0 ? (infiniteScrollDistance * this.thrPc) : this.thrPx;

      const scrolledDistance = this.panel.clientHeight + event.target.scrollTop;
      this.startEvent = this.panel.scrollHeight * .8;
      // console.log(scrolledDistance);

      if (scrolledDistance >= this.startEvent) {
        this.ngZone.run(() => this.infiniteScroll.emit());
      }
    });
  }

  // @HostListener('scroll') scroll () {
  //   console.log('scroll');
  // }

}
