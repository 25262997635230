import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollX]'
})
export class ScrollXDirective {

	mouseDown = false;
	startX: any;
	scrollLeft: any

  constructor(private el: ElementRef) { }

	@HostListener('mousedown', ['$event']) onMouseDown (e: MouseEvent) {
		this.startDragging(e);
	}

	@HostListener('mouseup') onMouseUp () {
		this.stopDragging();
	}

	@HostListener('mouseleave') onMouseLeave () {
		this.stopDragging();
	}

	@HostListener('mousemove', ['$event']) onMouseMouve (e: MouseEvent) {
		this.moveEvent(e)
	}

	startDragging(e: MouseEvent) {
		this.mouseDown = true;
		this.startX = e.pageX - this.el.nativeElement.offsetLeft;
		this.scrollLeft = this.el.nativeElement.scrollLeft;
	}
	stopDragging() {
		this.mouseDown = false;
	}
	moveEvent(e: MouseEvent) {
		e.preventDefault();
		if (!this.mouseDown) { return; }
		const x = e.pageX - this.el.nativeElement.offsetLeft;
		const scroll = x - this.startX;
		this.el.nativeElement.scrollLeft = this.scrollLeft - scroll;
	}
}
