import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MessageService {
	get required(): string {
		return '* Campo requerido';
	}

	get obligatory(): string {
		return '* Este campo es obligatorio';
	}

	get fiveCharacters(): string {
		return 'M\u00EDnimo 5 caracteres';
	}

	get sixtyCharacters(): string {
		return 'M\u00E1ximo 60 caracteres';
	}

	get fiftyCharacters(): string {
		return 'M\u00E1ximo 50 caracteres';
	}

	get thirtyFiveCharacters(): string {
		return 'M\u00E1ximo 35 caracteres';
	}

	get twoHundredCharacters(): string {
		return 'M\u00E1ximo 200 caracteres';
	}

	get rangeDates(): string {
		return 'Rango incorrecto de fechas';
	}

	get email(): string {
		return 'No es un correo v\u00E1lido';
	}

	get generalMessage(): string {
		return 'Servicio temporalmente no disponible, verifica tu conexi\u00F3n a internet e intenta más tarde.';
	}

	get warning(): string {
		return 'Aviso';
	}

	get error(): string {
		return 'Error';
	}

	get allFieldRequired(): string {
		return 'Todos los campos son obligatorios';
	}

	get errorMegas(): string {
		return 'Máximo una imagen de 4 MB.';
	}

	get typeFile(): string {
		return 'Formato de archivo incorrecto';
	}

	get oneFile(): string {
		return 'Solo se permiten un archivo';
	}

	get noRecords(): string {
		return 'No hay registros';
	}

	get errorDownload(): string {
		return 'Ocurri\u00F3 un error al descargar el archivo';
	}

	get errorLogin(): string {
		return 'La contraseña o correo son incorrectos. Verifica la información por favor';
	}
}
