import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserDialog } from '../../models/user';

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.css']
})
export class UsersDialogComponent implements OnInit {

	@Input() dataUsers: UserDialog[];
	@Output() close: EventEmitter<any> = new EventEmitter();
	dataSource = new MatTableDataSource<UserDialog>();
	displayedColumns: string[] = ['id', 'name'];
	alfanumerico: any = /^([a-zA-Z0-9ÑÁÉÍÓÚÜñáéíóú ])$/;
	allList: UserDialog[] = [];

  constructor() { }
  ngOnInit(): void {
  }

	ngAfterViewInit () {
		this.allList = this.dataUsers;
		this.dataSource.data = this.dataUsers;
	}

	filterData (data: any) {
		const splitValue: string[] = data.value.split('');
		const arrayValue = splitValue.filter((item: any, index: number) => {
			if (this.alfanumerico.test(item)) {
				return item;
			}
		})
		data.value = arrayValue.join('');
		const filterValue = data.value;
		if (filterValue.length >= 3) {
			this.dataSource.filter = filterValue.trim().toLowerCase();
		} else if (filterValue.length === 0) {
			this.dataSource.filter = '';
		}
	}

}
