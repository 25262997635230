import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './pages/header/header.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StylePaginatorDirective } from './directives/style-paginator.directive';
import { InfoModalComponent } from './pages/info-modal/info-modal.component';
import { MaterialModule } from '../modules/material/material.module';
import { TableFilterComponent } from './pages/table-filter/table-filter.component';
import { SelectSearchComponent } from './pages/select-search/select-search.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PaginatorComponent } from './pages/paginator/paginator.component';
import { PaginatorLocalComponent } from './pages/paginator-local/paginator-local.component';
import { FormatPipe } from './pipes/format.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { ModalResumenComponent } from './pages/modal-resumen/modal-resumen.component';
import { UsersDialogComponent } from './pages/users-dialog/users-dialog.component';
import { ScrollXDirective } from './directives/scroll-x.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { SanitizeInputDirective } from './directives/sanitize-input.directive';
import {InputSwitchModule} from 'primeng/inputswitch';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { DropdownInfiniteDirective } from './directives/dropdown-infinite.directive';
import { CountPipe } from './pipes/count.pipe';
import { DownloadSVGComponent } from './svg/download.component';
import { MoneySvgComponent } from './svg/money-svg.component';
import { SendSvgComponent } from './svg/send-svg.component';
import { CounterComponent } from './pages/counter/counter.component';
import { CounterSimpleComponent } from './pages/counter-simple/counter-simple.component';
import { EditSvgComponent } from './svg/edit-svg.component';
import { CrownComponent } from './svg/crown.component';
import { CheckboxComponent } from './pages/checkbox/checkbox.component';
import { DropdownCustomComponent } from './pages/dropdown-custom/dropdown-custom.component';
import {DropdownModule} from 'primeng/dropdown';


@NgModule({
  declarations: [
    HeaderComponent,
    AutofocusDirective,
    StylePaginatorDirective,
    InfoModalComponent,
    TableFilterComponent,
    SelectSearchComponent,
    PaginatorComponent,
    PaginatorLocalComponent,
    FormatPipe,
    MoneyPipe,
    ModalResumenComponent,
    UsersDialogComponent,
    ScrollXDirective,
    DragDropDirective,
    SanitizeInputDirective,
    DropdownInfiniteDirective,
    CountPipe,
    DownloadSVGComponent,
    MoneySvgComponent,
    SendSvgComponent,
    CounterComponent,
    CounterSimpleComponent,
    EditSvgComponent,
    CrownComponent,
    CheckboxComponent,
    DropdownCustomComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    InputSwitchModule,
    OverlayPanelModule,
    DropdownModule
  ],
	exports: [
		HeaderComponent,
		StylePaginatorDirective,
		TableFilterComponent,
		SelectSearchComponent,
		PaginatorComponent,
		PaginatorLocalComponent,
		FormatPipe,
		MoneyPipe,
    CountPipe,
		ModalResumenComponent,
		UsersDialogComponent,
		ScrollXDirective,
		DragDropDirective,
    SanitizeInputDirective,
    HeaderComponent,
    DropdownInfiniteDirective,
    DownloadSVGComponent,
    MoneySvgComponent,
    SendSvgComponent,
    CounterComponent,
    CounterSimpleComponent,
    EditSvgComponent,
    CrownComponent,
    CheckboxComponent,
    DropdownCustomComponent,
	]
})
export class SharedModule { }
