import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
	OwlDateTimeModule,
	OwlNativeDateTimeModule,
	OwlDateTimeIntl,
} from 'ng-pick-datetime';
import { MatTableModule } from '@angular/material/table';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './dutch-paginator-intl';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SecurityInterceptor } from './interceptors/security.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './store/app.reducer';


registerLocaleData(localeEs, 'es');

export class DefaultIntl extends OwlDateTimeIntl {
	public cancelBtnLabel = 'Cancelar';
	public setBtnLabel = 'Aceptar';
	public rangeFromLabel = 'De';
	public rangeToLabel = 'A';
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		SharedModule,
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		NgbModule,
		NgxSpinnerModule,
		HttpClientModule,
		OverlayModule,
		MatSelectModule,
		MatMenuModule,
		TabsModule,
		ModalModule.forRoot(),
		BsDropdownModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		MatTableModule,
		TreeViewModule,
		NgMultiSelectDropDownModule.forRoot(),
		NgxMatSelectSearchModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireStorageModule,
		AngularFirestoreModule,
		StoreModule.forRoot(appReducers),
		!environment.production
			? StoreDevtoolsModule.instrument({
					maxAge: 25, // Retains last 25 states
					logOnly: environment.production, // Restrict extension to log-only mode
					autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			  })
			: [],
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{ provide: OwlDateTimeIntl, useClass: DefaultIntl },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SecurityInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RetryInterceptor,
			multi: true,
		},
		{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
		/*{ provide: LOCALE_ID, useValue: 'es'},*/
		{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
