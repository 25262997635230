import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.length >= args[0] ? value.substring(0, args[0]) + "..." : value;
  }

}
