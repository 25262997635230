import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Counter } from '../../models/counter.model';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css'],
})
export class CounterComponent implements OnInit {

  @Input() data: Counter;
  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  selectCount () {
    if (!this.data.count) return;
    this.select.emit();
  }

}
