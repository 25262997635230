import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownCustom, FilterValue, TypeChip } from '../../models/dropdown.model';

@Component({
  selector: 'app-dropdown-custom',
  templateUrl: './dropdown-custom.component.html',
  styleUrls: ['./dropdown-custom.component.css']
})
export class DropdownCustomComponent implements OnInit {

  @Input() optionsDropdown: DropdownCustom[] = [];
  @Input() name = '';
  @Input() icon = '';
  @Input() image = '';
  @Input() id = 0;
  @Input() disabled = false;
  @Output() change = new EventEmitter<FilterValue>();
  optionSelected: DropdownCustom | null = null;

  constructor() { }

  ngOnInit(): void {
  }

  emitValue (value: DropdownCustom) {
    this.change.emit({
      id: this.id,
      values: value.id,
      filterChip: {
        id: this.id,
        name: value.name,
        icon: this.icon || this.image,
        type: this.icon ? TypeChip.ICON : TypeChip.IMAGE,
        last: true,
      },
    });
  }

}
