import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css']
})
export class SelectSearchComponent implements OnInit {

  @Input() data: any = [];
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() placeholderLabel: string = '';
  @Input() width: string = "100%";
  @Input() lengthString: number = 45;
  @Input() isLength: boolean = false;
  @Output() onChangeSelect: EventEmitter<any> = new EventEmitter<any>();

  FilterCtrl: FormControl = new FormControl();
  filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  noResults: string = 'No se encontraron resultados';
  _onDestroy = new Subject<void>();
  fiId: number = 0;


  constructor() {
  }

  ngOnInit(): void {
    this.filtered.next(this.data);
    this.configFilterSelect();
  }

  ngAfterViewInit() {
    this.configFilterSelect();
  }

  protected filter() {
    if (!this.data) {
      return;
    }
    let search = this.FilterCtrl.value;

    if (!search) {
      this.filtered.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filtered.next(
      this.data.filter((element: any) => element.name.toLowerCase().indexOf(search) > -1
      )
    );

  }

  configFilterSelect() { //Add new placeholder text
    this.FilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filter();
    });
  }

  onSelectChange(e: any) {
    this.onChangeSelect.emit(e);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


}
