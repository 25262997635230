<mat-sidenav-container>
	<!--Menu descriptions-->
	<mat-sidenav
		[disableClose]="true"
		mode="side"
		class="mat-elevation-z8 sidenavMenu d-flex flex-column justify-content-between"
		#sidenav
		opened
	>
		<div class="sidenav">
			<img src="assets/logo.svg" alt="trader-logo" class="logo"  />
			<div class="profile-image-container">
				<img [src]="(logo | async) || 'assets/profile-image.jpg'" alt="profile-image" class="profile-image"/>
				<div class="profile-arrow">
					<mat-icon [matMenuTriggerFor]="colapseMenu" (menuOpened)="openMenu()" class="logout-icon">chevron_right</mat-icon>
					<mat-menu #colapseMenu="matMenu" [class]="newUser ? 'options-menu' : ''">
						<button mat-menu-item [matMenuTriggerFor]="accounts" (menuOpened)="openMenu()" *ngIf="newUser">Cambio de cuentas</button>
						<button mat-menu-item class="btn-logout" (click)="logout()">Cerrar sesión</button>
					</mat-menu>
					<mat-menu #accounts="matMenu" overlayPanelClass="accounts" class="options-menu accounts">
						<button mat-menu-item *ngFor="let operation of operationsTypes" (click)="changeAccount(operation.id)">
							<img [src]="'assets/icons/'+ operation.icon +'.svg'" width="15" />
							{{operation.id === 1 ? 'Oficiales' : 'Prueba'}}
						</button>
					</mat-menu>
				</div>
			</div>
			<div
				class="link-menu pointer"
				[ngClass]="{'disabled': newUser && !projectSelected && !allClients}"
				*ngFor="let option of menuOptions"
				(mouseenter)="isOnHover(option)"
				(mouseleave)="inIconMenu = false; closeMenu()"
				(click)="navigate(option); isParent(); eventSelected(option)"
				id="link-{{ option.moduleId }}"
			>
				<img src="assets/icons/sidenav-icons/{{ option.icon }}" />
				<span class="text-center">{{ option.moduleName }}</span>
			</div>
		</div>
		<mat-icon #menuOptionIcon class="link-menu-arrow" style="display: none"
			>play_arrow</mat-icon
		>
		<div
			class="menu-flotante"
			#menuOptionsDiv
			[ngStyle]="{'background-color': operationType ? operationType.color : 'var(--blue-p)'}"
			(mouseenter)="inSubMenu = true"
			(mouseleave)="closeSubMenu()"
		>
			<div
				class="menu-flotente-link pointer"
				*ngFor="let m of optionSelected?.modules"
				(click)="clearLocalStorage(m); eventSelected(m); navigate(m)"
			>
				{{ m.moduleName }}
			</div>
		</div>
	</mat-sidenav>

	<mat-sidenav-content class="margin-content">
		<div class="content super" [ngClass]="{'super': superAdmin}">
			<app-header *ngIf="superAdmin"></app-header>
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
