import {ActionReducerMap} from "@ngrx/store";
import * as auth from '../auth/auth.reducer';
import * as project from '../project-management/project.reducer';
import * as notification from '../notification/notification.reducer';
import * as moduleManagment from "./settings/module-managment/module.reducer";

export interface AppState {
	auth: auth.AuthState
	project: project.ProjectState,
	notification: notification.NotificationState,
	moduleManagment: moduleManagment.ModuleState
}

export const appReducers: ActionReducerMap<AppState> = {
	auth: auth.authReducer,
	project: project.projectReducer,
	notification: notification.notificationReducer,
	moduleManagment: moduleManagment.moduleReducer
}
