import { createReducer, on } from "@ngrx/store";
import * as actions from "./notification.actions";
import { MessageObject } from "./models/message.model";
import { UserList } from "./models/segmentacion.model";
import { Frequency } from "./models/frequency.model";


export interface NotificationState {
  message: MessageObject;
  users: UserList[];
  countUsers: number;
  frequency: Frequency;
  type: number;
  reasign: boolean;
}

const initialState: NotificationState = {
  message: {
    title: '',
    description: '',
    boton: '',
    url: '',
    project: -1,
    valid: false
  },
  users: [],
  countUsers: 0,
  frequency: {
    startDate: '',
    endDate: '',
    time: '',
    valid: false,
    type: 0
  },
  type: -1,
  reasign: false
}

export const notificationReducer = createReducer(
  initialState,
  on(actions.updateMessage, (state, { message }) => ({...state, message})),
  on(actions.setUsers, (state, { users }) => ({...state, users})),
  on(actions.setUsersCount, (state, { count }) => ({...state, countUsers: count})),
  on(actions.setType, (state, { typeS }) => ({...state, type: typeS})),
  on(actions.setFrecuency, (state, { frecuency }) => ({...state, frequency: frecuency})),
  on(actions.setReasign, (state, { reasign }) => ({...state, reasign})),
  on(actions.updateFrecuency, (state, { value, key }) => ({...state, frequency: { ...state.frequency, [key]: value }})),
  on(actions.updateMessageKey, (state, { value, key }) => ({...state, message: { ...state.message, [key]: value }})),
  on(actions.clearNotification, (state) => ({...state, ...initialState}))
)