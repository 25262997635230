import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edit-svg',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 3H3.11111C2.55121 3 2.01424 3.23413 1.61833 3.65087C1.22242 4.06762 1 4.63285 1 5.22222V20.7778C1 21.3671 1.22242 21.9324 1.61833 22.3491C2.01424 22.7659 2.55121 23 3.11111 23H17.8889C18.4488 23 18.9858 22.7659 19.3817 22.3491C19.7776 21.9324 20 21.3671 20 20.7778V13" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.8969 1.65998C19.3477 1.2374 19.959 1 20.5965 1C21.2339 1 21.8453 1.2374 22.296 1.65998C22.7468 2.08256 23 2.6557 23 3.25332C23 3.85093 22.7468 4.42407 22.296 4.84665L11.5322 14.9378L7 16L8.13304 11.7511L18.8969 1.65998Z" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  styles: [
  ]
})
export class EditSvgComponent implements OnInit {

  @Input() color = '#EF6A00';

  constructor() { }

  ngOnInit(): void {
  }

}
