import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-resumen',
  templateUrl: './modal-resumen.component.html',
  styleUrls: ['./modal-resumen.component.css']
})
export class ModalResumenComponent implements OnInit {

	@Input() titleModal: string = '';
	@Input() fileName: string = '';
	@Input() acceptedUsers: any[] = [];
	@Input() deniedUsers: any[] = [];
	@Input() titleButton: string = 'Conciliar';
	@Input() avanceVentas: boolean = false;
	@Input() textAceppted = 'Válidos';
	@Input() textDenied = 'Inválidos';
	@Input() invalidAction = false;
	@Output() action: EventEmitter<any> = new EventEmitter();
	@Output() error: EventEmitter<any> = new EventEmitter();
	@Output() otherFile: EventEmitter<any> = new EventEmitter();
	@Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

	startAction () {
		this.action.emit('Inicio');
	}

	startError () {
		this.error.emit('Error');
	}

	startOther () {
		this.otherFile.emit('Other');
	}

	startClose () {
		this.close.emit();
	}

}
