import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CounterSimple } from '../../models/counter.model';

@Component({
  selector: 'app-counter-simple',
  templateUrl: './counter-simple.component.html',
  styleUrls: ['./counter-simple.component.css']
})
export class CounterSimpleComponent implements OnInit, OnChanges {

  @Input() data: CounterSimple;
  @Output() select = new EventEmitter();
  currentStyles: Record<string, string> = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  setCurrentStyles () {
    return {
      'border-color': this.data.color,
      'background-color': this.data.active ? this.data.color : '#F4F4F4',
      'color': this.data.active ? '#FFF' : this.data.color
    }
  }

  selectCount () {
    if (!this.data.count) return;
    this.select.emit();
  }

}
