import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'svg-download',
  template: `
    <svg [attr.width]="size" [attr.height]="size" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 9.5V12.1667C13 12.5203 12.8595 12.8594 12.6095 13.1095C12.3594 13.3595 12.0203 13.5 11.6667 13.5H2.33333C1.97971 13.5 1.64057 13.3595 1.39052 13.1095C1.14048 12.8594 1 12.5203 1 12.1667V9.5" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.66602 6.16602L6.99935 9.49935L10.3327 6.16602" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 9.5V1.5" [attr.stroke]="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  styles: [
  ]
})
export class DownloadSVGComponent implements OnInit {

  @Input() color: string = '#00A3B3';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit(): void {
  }

}
