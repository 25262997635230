import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaginatorService } from '../../services/paginator.service';
import { IPaginator } from './paginator.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnDestroy {

  @Input() defaultSize: number;
  @Input() pageSizeOptions: number[];
	@Input() newDesign: boolean = false;
  totalRecords: number = 0;
  totalRecordsSuscription: Subscription;
  @Output() actualPage = new EventEmitter<number>();
  @Output() changePage = new EventEmitter<number>();
  noRecords: boolean = true;
  pages: IPaginator[] = [];
  activePage: number;
  arrowNextActive: boolean = false;
  arrowBackActive: boolean = false;
  @Input() arrowNextBack: boolean = false;
  indexPage: number = 0;
  totalPages: number = 0;
  arrowFirstActive: boolean = false;
  arrowLastActive: boolean = false;
  @Input() arrowFirstLast: boolean = false;
  @Input() pagesText: boolean = false;



  constructor(private paginatorService: PaginatorService) { }

  ngOnInit(): void {
    this.totalRecordsSuscription = this.paginatorService.totalRecords$.subscribe(
      response => {
        if (response !== undefined && response !== null && response >= 0) {
         this.configPages(response);
        }
      }, error => {
        console.log(error)
      }
    )
  }

  configPages(response:any){
    this.totalRecords = response;
    this.noRecords = false;
    this.pages = [];
    for (let i = 0, limit = this.totalRecords / this.defaultSize; i < limit; i++) {
      const obj: IPaginator = {
        active: false,
        hidden: true
      }
      this.pages[i] = obj;
    }

    this.totalPages = this.pages.length;
    this.arrowLastActive = this.arrowNextActive = this.totalPages > 1

    this.pageZero();
    this.activePage = 0;
    this.indexPage = 0;
    this.arrowFirstActive = this.arrowBackActive = false;
  }

  ngOnDestroy(): void {
    this.totalRecordsSuscription.unsubscribe();
  }

  pageZero() {
		if (this.pages.length > 0) {
			this.pages[0].active = true;
			this.pages[0].hidden = false;
			if (this.totalPages >= 2) {
				this.pages[1].active = false;
				this.pages[1].hidden = false;
			}
			if (this.totalPages >= 3) {
				this.pages[2].active = false;
				this.pages[2].hidden = false;
			}
		}
  }

  sendPage(page: number) {
    this.indexPage = page;
    if (page !== this.activePage) {
      this.inactiveAll();
      this.activePage = page;
      this.pages[page].active = true;
      this.pages[page].hidden = false;
      if (page > 0) {
        this.pages[page - 1].active = false;
        this.pages[page - 1].hidden = false;
        if ((page + 1) < this.totalPages) {
          this.pages[page + 1].active = false;
          this.pages[page + 1].hidden = false;
        }
      } else {
        this.pageZero();
      }
      this.arrowFirstActive = this.arrowBackActive = (this.totalPages > 1 && this.indexPage > 0);
      this.arrowLastActive = this.arrowNextActive = (this.indexPage + 1) < this.totalPages;
      this.actualPage.emit(page + 1);
    }
  }

  inactiveAll() {
    this.pages.forEach(element => {
      element.hidden = true;
      element.active = false;
    })
  }

  sendPageZero() {
    this.changePage.emit(this.defaultSize);
  }

  back() {
    if ((this.indexPage - 1) >= 0) {
      this.sendPage(--this.indexPage);
    }
  }

  next() {
    if ((this.indexPage + 1) < this.totalPages) {
      this.sendPage(++this.indexPage);
    }
  }

  first() {
    this.sendPage(0);
  }

  last() {
    this.sendPage(this.totalPages - 1);
  }

}
