import { createSelector } from "@ngrx/store";
import { AppState } from "../store/app.reducer";
import { ProjectState } from "./project.reducer";

export const selectFeature = (state: AppState) => state.project;

export const selectClientId = createSelector(
  selectFeature,
  (state: ProjectState) => state.clientId
)

export const selectProject = createSelector(
  selectFeature,
  (state: ProjectState) => ({projectId: state.projectId, name: state.projectName, allClient: state.allClient})
)

export const selectLogo = createSelector(
  selectFeature,
  (state: ProjectState) => state.logo
)

export const selectAllClient = createSelector(
  selectFeature,
  (state: ProjectState) => state.allClient
)

export const selectProjectsForCreation = createSelector(
  selectFeature,
  (state: ProjectState) => state.projectsForCreation
)

export const isInvalidProjectsForCreation = createSelector(
  selectFeature,
  (state: ProjectState) => state.projectsForCreation.length ? state.projectsForCreation.some(p => !p.valid) : true
)

export const isValidProject = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject.info ? state.configurationProject.info.valid : false
)

export const selectConfigurationProject = createSelector(
  selectFeature,
  (state: ProjectState) => state.configurationProject
)