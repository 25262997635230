import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit, OnChanges {

  value = false;
  @Input() checked = false;
  @Output() change = new EventEmitter<boolean>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checked) {
      this.value = changes.checked.currentValue;
    }
  }

  ngOnInit(): void {
    if (this.checked) {
      this.value = this.checked;
    }
  }

  changeCheck () {
    this.value = !this.value;
    this.change.emit(this.value)
  }

}
