import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'send-svg',
  template: `
    <svg [attr.width]="size" [attr.height]="size" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.74433 3.49335C8.74433 2.49738 8.74433 1.50141 8.74433 0.505439C8.74433 0.312671 8.76271 0.133959 8.92482 0.0395827C9.08693 -0.0547934 9.21395 0.0335587 9.33929 0.158055C11.1743 1.99739 13.0093 3.8327 14.8443 5.67203C15.0482 5.87685 15.0532 6.11982 14.851 6.32062C13.0076 8.16798 11.1609 10.0133 9.31756 11.8627C9.19389 11.9852 9.06688 12.0474 8.91647 11.9571C8.77107 11.8687 8.74433 11.7101 8.74433 11.5294C8.746 10.5113 8.74433 9.49326 8.74433 8.4752C8.74433 8.3989 8.74433 8.32259 8.74433 8.26035C7.81178 8.26035 6.90931 8.24227 6.00852 8.26637C5.16287 8.28846 4.3423 8.49528 3.54679 8.84066C2.43041 9.3266 1.45106 10.0756 0.603748 11.0695C0.48509 11.2081 0.358076 11.2985 0.195967 11.2181C0.0238295 11.1318 -0.00959512 10.9471 0.00210352 10.7422C0.0154734 10.5153 0.0154734 10.2884 0.0422131 10.0635C0.317967 7.65795 1.32739 5.8387 3.08386 4.63389C3.97964 4.01944 4.95564 3.74234 5.98846 3.75037C6.89595 3.7584 7.8051 3.75037 8.74433 3.75037V3.48933V3.49335ZM9.37104 1.17611C9.37104 1.30663 9.37104 1.38294 9.37104 1.45924C9.37104 2.31666 9.37104 3.17408 9.37104 4.02948C9.37104 4.37285 9.26074 4.50337 8.97162 4.50337C7.95384 4.50337 6.93438 4.49936 5.9166 4.50337C3.32285 4.51944 1.1235 6.77242 0.699008 9.84265C0.695666 9.86273 0.700679 9.88482 0.704022 9.929C0.837721 9.80651 0.961392 9.69205 1.08673 9.5796C2.30506 8.49327 3.66879 7.83264 5.16956 7.58967C5.56731 7.52542 5.97175 7.50132 6.37285 7.4953C7.23855 7.48124 8.10425 7.49128 8.96995 7.49128C9.26074 7.49128 9.36937 7.61979 9.36937 7.96517C9.36937 8.89488 9.36937 9.82458 9.36937 10.8206C10.9905 9.19808 12.5815 7.60775 14.1892 5.99733C12.5815 4.38892 10.9938 2.79858 9.36937 1.1721L9.37104 1.17611Z" [attr.fill]="color"/>
    </svg>
  `,
  styles: [
  ]
})
export class SendSvgComponent implements OnInit {

  @Input() color: string = '#FC5806';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit(): void {
  }

}
