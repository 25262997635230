import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, timer, throwError, of } from 'rxjs';
import { retryWhen, tap, mergeMap } from 'rxjs/operators';
import {NgxSpinnerService} from "ngx-spinner";


@Injectable()
export class RetryInterceptor implements HttpInterceptor {

	retryDelay = 3000;
  retryMaxAttempts = 3;

  constructor(private loader: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<any>) => {
				return errors.pipe(
					mergeMap((err: any, count: number) => {
						// throw error when we've retried ${retryMaxAttempts} number of times and still get an error
						if (count === this.retryMaxAttempts) {
							this.loader.hide();
							return throwError(err);
						}
						return of(err).pipe(
							tap((error: any) => console.log(`Retrying... Retry count ${count + 1}`)),
							mergeMap(() => timer(this.retryDelay))
						);
					})
				);
			})
    )
  }
}
