import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk'
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SecurityService } from '../security/security.service';

@Injectable({
  providedIn: 'root'
})
export class S3 {
	API = environment.API.USER
  s3:any;
	attempts: number = 0;

  constructor(private http: HttpClient,
							private security: SecurityService) {
    this.setCredentialsAWS();
  }

  async setCredentialsAWS() {
    AWS.config.region = 'us-east-1';

    if (environment.ENV === 'dev') {
      await this.awsAccess();
    }
    if (environment.ENV === 'qa') {
      await this.awsAccess();
    }
    if (environment.ENV === 'prod') {
      await this.awsAccess();
    }
  }

  async awsAccess(){
		let s3Data: any = {};
		try {
			if (this.attempts > 3) {
				this.attempts = 0;
				return;
			}
			let secret = new Promise((resolve, reject) => {
				this.http.post(`${this.API}/getSecret`, {}).subscribe((response: any) => {
						console.log('RESPONSE = ', response);
						resolve(response);
					}, (err: any) => reject(err)
				);
			});
			const s = await secret;
			let decrypted: any =  await this.security.decrypt(s);
			console.log('s3Data = ', decrypted);
			s3Data = decrypted.body.data.items[0] || {};
			this.s3 = new AWS.S3({
				accessKeyId: s3Data.accessKey,
				secretAccessKey: s3Data.secretKey,
				signatureVersion: 'v4'
			});
		} catch (e) {
			this.attempts++;
			console.error(e);
			setTimeout(() => {
				this.awsAccess();
			}, 5000);
		}

  }

  async upload(bucket: any, key: any, file: any, contentType: any) {
    const promiseUploadFile = new Promise(async (resolve, reject) => {
      const params = {
        Bucket: bucket,
        Key: key,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType
      };
			console.log('params = ', params);
			await this.awsAccess();
      this.s3.upload(params, (err:any, data:any) => {
        if (err) {
					console.log('ERROR = ', err);
          return reject({ error: err });
        }
        return resolve(data);
      });
    });
    return await promiseUploadFile;
  }

  async download(bucket:any, key:any) {
    const params = {
      Bucket: bucket,
      Key: key
    };
    return await this.s3.getObject(params).promise();
  }

	async getSignedUrl (bucket: string, key: string) {
		return  await this.s3.getSignedUrl('getObject', {
			Bucket: bucket,
			Key: key,
		});
	}
}
