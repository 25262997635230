import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthStorage} from "../auth/models/login.model";
import {USER} from "../shared/models/user";

@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate {
  constructor(private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let user: USER = localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user') || '')) : null;
    let auth: AuthStorage = localStorage.getItem('auth') ? JSON.parse(atob(localStorage.getItem('auth') || '')) : null;
		console.log(auth, user)
		if ((auth && auth.user && auth.operationType) || user !== null) {
			console.log(auth, user)
			this.router.navigate(['/admin']);
		}
    return true;
  }
}
