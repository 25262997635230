export const fileExtension = {
  pdf: 'pdf',
  png: 'png',
  jpeg: 'jpeg',
  jpg: 'jpg',
  mp3: 'mp3',
  mp4: 'mp4',
  xlsx: 'xlsx',
  xlsm: 'xlsm'
};

export const TRADER_LAYOUTS = {
	missions: {
		massive: 'Misiones/Layout/Layout_Asignacion_Misiones.xlsx',
		manually: 'Misiones/Layout/Layout_Usuarios_Mision.xlsx'
	}
}


// EL REGEXP DE RFC FUERON OBTENIDAS DE LA PAGINA OFICIAL DEL SAT https://portalsat.plataforma.sat.gob.mx/ConsultaRFC/
export const REGULAR_EXPRESSIONS = {
	EMAIL: new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
	NUMERIC: new RegExp(/^\d+$/),
	ALPHANUMERIC: new RegExp(/^[A-Za-zÀ-ÿ0-9ÑÁÉÍÓÚÜñáéíóúü\s,.]+$/),
  ALPHABETIC: new RegExp(/^[A-Za-zÀ-ÿÑÁÉÍÓÚÜñáéíóúü\s,.]+$/),
  ZERO_WIDTH: new RegExp(/[\u200B-\u200D\uFEFF]/g),
	TRADER_ALPHANUMERIC: new RegExp(/^[A-Za-zÀ-ÿ0-9ÑÁÉÍÓÚÜñáéíóúü\s,#.'-]+$/),
	RFC_PM: new RegExp(/^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\d])|[3][01])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\d])|[3][0])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\d])([A-Z0-9]{3}))|(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/),
	RFC_PF: new RegExp(/^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\d])|[3][01])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\d])|[3][0])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\d])([A-Z0-9]{3}))|(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$/),
  RFC_PF_M: new RegExp(/^(([A-Za-zÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\d])|[3][01])([A-Za-z0-9]{3}))|(([A-Za-zÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\d])|[3][0])([A-Za-z0-9]{3}))|(([A-Za-zÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\d])([A-Za-z0-9]{3}))|(([A-Za-zÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Za-z0-9]{3}))$/),
  CURP:  new RegExp(/^([A-ZÑ][AEIOUX][A-ZÑ]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/),
}

export const layouts = {
  employees_load: 'Layouts/Layout_Carga_Empleados.xlsx',
  employees_access: 'Layouts/Layout_Accesos.xlsm',
  layout: 'Layouts/Layout.xlsm',
  employees: 'Layouts/Layout_Empleados.xlsm',
  externos: 'Layouts/Layout_Preregistro.xlsm',
  carga_ubicaciones: 'Layouts/Layout_Carga_Ubicaciones.xlsx',
	layout_usuarios: 'layout/users/Layout_Usuarios.xlsx',
	layout_conciliacion: 'layout/Conciliacion/Conciliador_de_Pagos.csv'
};

export const contentType = {
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  text: 'text/plain'
};

export const dropdownSettings = function (idField:any, textField:any) {
  return {
    singleSelection: false,
    idField: idField,
    textField: textField,
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseclecionar todo',
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos por mostrar',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
};

export const dataTable = {
  'language': {
    'lengthMenu': 'Mostrar _MENU_ registros por página',
    'zeroRecords': 'No hay registros',
    'info': 'Mostrando _PAGE_ de _PAGES_',
    'infoEmpty': 'No hay registros',
    'infoFiltered': '(filtered from _MAX_ total records)',
    'search': 'Buscar',
    'paginate': {
      'previous': 'Atras',
      'next': 'Adelante'
    }
  }
};

//-----------------------trade const
export const NGX_DATATABLE_PLANNING = {
  'colums': [
    { 'name': 'Planeación', 'prop': 'name' },
    { 'name': 'Fecha inicio', 'prop': 'dateIn' },
    { 'name': 'Fecha fin', 'prop': 'dateOut' },
    { 'name': 'Empresa', 'prop': 'company' },
    { 'name': 'Status', 'prop': 'status' },
  ]
};

export const NGX_DATATABLE_CARGA = {
  'colums': [
    { name: 'Fecha carga', prop: 'bulkUploadDate' },
    { name: 'Estado', prop: 'description' },
    { name: 'Tipo', prop: 'type' },
    { name: 'Cargas fallidas', prop: 'recordError' },
    { name: 'Cargas exitosas', prop: 'recordSuccess' },
  ]
};

export const NGX_DATATABLE_ASSINGMENT = {
  'colums': [
    { 'name': 'Id', 'prop': 'assignment.assignmentId' },
    { 'name': 'Inicio', 'prop': 'assignment.dateIn' },
    { 'name': 'Fin', 'prop': 'assignment.dateOut' },
    { 'name': 'Status', 'prop': 'assignment.status' },
    { 'name': 'Tienda', 'prop': 'assignment.store.name' },
    { 'name': 'Total actividades', 'prop': 'assignment.store.activities.length' },
    { 'name': 'Trader', 'prop': 'assignment.trader.name' },
  ]
};

export const NGX_DATATABLE_PRODUCTS = {
  'colums': [
    { 'name': 'Sku', 'prop': 'sku' },
    { 'name': 'Nombre', 'prop': 'name' },
    { 'name': 'Compañia', 'prop': 'company' },
    { 'name': 'Categoria', 'prop': 'category' },
    { 'name': 'Marca', 'prop': 'mark' },
    { 'name': 'Segmento', 'prop': 'segment' },
    { 'name': 'Presentación', 'prop': 'presentation' },
    { 'name': 'Abreviación', 'prop': 'unityAbreviation' },
    { 'name': 'Unidad', 'prop': 'unity' },
  ]
};
const COORDENADAS = [
  {'prop':'latitude'},
  {'prop':'longitude'}
]
export const NGX_DATATABLE_STORE_ROUTE = {
  'colums': [
    { 'name': 'Nombre', 'prop': 'name' },
    { 'name': 'Número', 'prop': 'number' },
    { 'name': 'Estado', 'prop': 'state' },
    { 'name': 'Municipio', 'prop': 'municipal' },
    { 'name': 'Colonia', 'prop': 'neighborhood' },
    { 'name': 'Codigo Postal', 'prop': 'postCode' },
    { 'name': 'Dirección', 'prop': 'address' },
    { 'name': 'Coordenadas', 'prop': COORDENADAS},
    { 'name': 'Formato', 'prop': 'format' },
    { 'name': 'Cadena Comercial', 'prop': 'commercialChain' },
    { 'name': 'Canal de Distribucion', 'prop': 'distributionChannel' },
  ]
};

export const NGX_DATATABLE_RESOURCES_STORE_FORMAT = {
  'colums': [
    {'name': 'Id','prop': 'resourceId'},
    {'name': 'Nombre de recurso','prop': 'name'},
    {'name': 'formato','prop': 'formatFile'},
]};

export const EXCEL_DATA = {
  VISIT: 'NO. VISITA',
  GEOCERCA: 'GEOCERCA MTS',
  ROUTE: 'RUTA',
  STORE: 'NO. TIENDA',
  TRADER: 'TRADER',
  D_START: 'FECHA ENTRADA',
  D_END: 'FECHA SALIDA',
  H_START: 'HORA ENTRADA',
  H_END: 'HORA SALIDA',
  URL: 'URL',
  ORIGIN: 'ORIGEN',
  ACTIVITY: 'ACTIVIDAD',
  SKU: 'SKU'
}


export const LAYOUT_DATA = {
  CLAVE: 'Clave Ubicacion',
  MISION: 'Clave Mision',
  USER: 'Usuario',
  START_TIME: 'Hora Inicio',
  END_TIME: 'Hora Fin'
}

export const LAYOUT_USERS ={
  NAME: 'NOMBRES',
  FIRST: 'APELLIDO PATERNO',
  SECOND: 'APELLIDO MATERNO',
  EMAIL: 'CORREO',
}

export const TABLE_MESSAGES = {
  'messages': {
    'emptyMessage': 'No se encontraron registros',
    'totalMessage': 'registros en total'
  }
};

export const FILE_EXTENSION = {
  PDF: 'pdf',
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  MP3: 'mp3',
  MP4: 'mp4',
  XLSX: 'xlsx',
  XLSM: 'xlsm'
};

export const LAYOUTS = {
  ASSIGNMENTS: 'trade/Layout_Asignacion_Tiendas.xlsx',
  BILLING: 'layout_assigments/layouts/Layout_billing.xlsx',
  MISSIONS_USERS: 'layout_assigments/layouts/Layout_misiones_usuarios.xlsx',
	NEW_MISSIONS_USERS: 'layout_assigments/layouts/Layout_asignacion_de_misiones.xlsx',
  PAYMENTS: 'layout_payments/layouts/Layout_pagos.xlsx',
  MISSIONS_USERS_MASIVE: 'layout_assigments/layouts/Layout_misiones_masivas.xlsx',
  UPLOAD_BILLING: 'billing/layout',
  TEST: 'trade/planning/2021/2/10/planing1612993431319.json',
  LAYOUT: 'layout_assigments/layout_mision_por_ubicacion_usuario.xlsx',
  USERS: 'layout/users/Layout_users.xlsx',
	PROJECT_CLIENT: 'layout/project/Cliente.xlsx',
	PROJECT: 'layout/project/Proyectos.xlsx'
};

export const CONTENT_TYPE = {
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  TXT: 'text/plain',
  JSON: 'application/json'
};

export const EXCEL_RESULT = {
  ORIGIN: 'Plannning_layout_Origin',
  SUCCESS: 'Plannning_layout_Success',
  ERROS: 'Plannning_layout_Errors'
};


export const RESPONSE_STATUS = {
  '204': { "data": "gcBAxGH7oDLLIBg2fkNhSW1JLpi0ZLEBwnyJxR6WBeHP36TSgGx72eD0ZUd9XrPU8YzZU68A7mlMEZmRi+hxQA==" }
};

export const ERROR_TYPE = Object.freeze({
  INCORRECTO: 'INCORRECTO',
  ACEPTADO: 'ACEPTADO',
  DUPLICADO: 'DUPLICADO'
})
