<div *ngIf="!isLength">
    <mat-form-field appearance="standard" class="input-style" [style.width]="width">
        <mat-select placeholder={{placeholder}} (selectionChange)="onSelectChange($event.value)" #singleSelect
        [disabled]="disabled">
            <mat-option>
                <ngx-mat-select-search [formControl]="FilterCtrl" [placeholderLabel]='placeholderLabel'
                noEntriesFoundLabel={{noResults}}>
                    <mat-icon ngxMatSelectSearchClear color="primary" class="btn-trash">delete</mat-icon>
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let data of filtered | async" [value]="data">
                        {{data.name}} 
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="isLength">
    <mat-form-field appearance="standard" class="input-style" [style.width]="width">
        <mat-select placeholder={{placeholder}} (selectionChange)="onSelectChange($event.value)" #singleSelect
        [disabled]="disabled">
            <mat-option>
                <ngx-mat-select-search [formControl]="FilterCtrl" [placeholderLabel]='placeholderLabel'
                noEntriesFoundLabel={{noResults}}>
                    <mat-icon ngxMatSelectSearchClear color="primary" class="btn-trash">delete</mat-icon>
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let data of filtered | async" [value]="data">
                        {{data.name | format:lengthString}} 
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

