import {createReducer, on} from "@ngrx/store";
import * as project from './project.actions';
import { ConfigurationProject, ProjectInfo, StepProject } from "./models/project.model";

export interface ProjectState {
	projectName: string;
	projectId: number;
	clientId: number;
	logo: string;
	allClient: boolean;
	projectsForCreation: ProjectInfo[];
	configurationProject: Partial<ConfigurationProject>;
}

export const initialState: ProjectState = {
	projectId: -1,
	projectName: '',
	clientId: -1,
	logo: '',
	allClient: false,
	projectsForCreation: [],
	configurationProject: {
		type: 1,
		step: StepProject.INFO
	}
}

export const projectReducer = createReducer(
	initialState,
	on(project.setProject, (state, {project}) => ({...state, ...project})),
	on(project.setClient, (state, {clientId}) => ({...state, clientId, allClient: clientId === 0})),
	on(project.setLogo, (state, {logo}) => ({...state, logo})),
	on(project.loadProject, (state, {project}) => ({...state, ...project})),
	on(project.unsetProject, (state) => ({...state, ...initialState})),
	on(project.loadProjectsForCreation, (state, {projects}) => ({...state, projectsForCreation: projects})),
	on(project.updateProjectsForCreation, (state, {project}) => ({
		...state, 
		projectsForCreation: [
			...state.projectsForCreation.map(p => {
				if (p.index === project.index) return project;
				return p
			}),
		]}
	)),
	on(project.loadAdminDesign, (state, {key, value}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			admin: {
				...state.configurationProject.admin,
				[key]: value
			}
		}
	})),
	on(project.loadProjectInfo, (state, {project}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			info: project,
		}
	})),
	on(project.clearProjectInfo, (state) => ({
		...state, 
		configurationProject: initialState.configurationProject
	})),
	on(project.selectStepProject, (state, {step}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			step,
		}
	})),
	on(project.selectTypeDesign, (state, {value}) => ({
		...state, 
		configurationProject: {
			...state.configurationProject,
			type: value
		}
	})),
)
