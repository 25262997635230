export interface EventCollectorData {
	event: events;
	date: string;
	idTrader: number;
	description: string;
}

export interface LogCollectionI {
	appVersion: string;
	plattform: string;
	device: string;
	environment: string;
	timestamp: number;
	type: string;
	module: string;
	class: string;
	method: string;
	deviceId: string;
	userId: number | string;
	data: any;
}

export interface EventCollectionI extends LogCollectionI {
	name: string;
}

export type events = 'click' | 'upload' | 'signIn' | 'signUp';

export const eventTypes = Object.freeze({
	click: 'click',
	upload: 'upload',
	signIn: 'sign_in',
	signUp: 'sign_up',
});

export const logType = Object.freeze({
	debug: 'debug',
	info: 'info',
	error: 'error',
});
