import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly API: string = environment.API.USER;
	private readonly API_LOGIN: string = environment.API.LOGIN;
	private readonly API_NEW_LOGIN: string = environment.API.NEW_LOGIN;

  constructor(private http: HttpClient) { }

  login(data: any) {
    return new Promise((resolve, reject) => {
      // this.http.post(`${this.API}/authenticate`, data).subscribe((response: any) => {
			this.http.post(`${this.API_LOGIN}/login`, data).subscribe((response: any) => {
          resolve(response);
        }, (err: any) => reject(err)
      );
    });
  }

	newLogin (data: any) {
		return this.http.post(`${this.API_NEW_LOGIN}/login`, data).toPromise();
	}

	getOperationTypes (idUser: number) {
		let params = new HttpParams().set('idUser', idUser);
		return this.http.get(`${this.API_NEW_LOGIN}/admin-operation-companies-info`, {params}).toPromise();
	}

  sendMail(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API}/resend-password`, data).subscribe((response: any) => {
          resolve(response);
        }, (err: any) => reject(err)
      );
    });
  }
}
