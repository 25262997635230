import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Response } from "../shared/models/response";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private readonly API = environment.SECURITY_API

  constructor(private http: HttpClient) { }

  encrypt(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API.ENCRYPT}`, data).subscribe((response: any) => {
          resolve(response);
        }, (err: any) => reject(err)
      );
    });
  }

	encrypt2(data: any) {
			return this.http.post(`${this.API.ENCRYPT}`, data, {headers:{skip:"true"}});
	}

  decrypt(data: any): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API.DECRYPT}`, data).subscribe((response: any) => {
           resolve(response);
        }, (err: any) => reject(err)
      );
    });
  }

	decrypt2(data: any) {
		return this.http.post(`${this.API.DECRYPT}`, data, {headers:{skip:"true"}});
	}

	healtCheck(url: string) {
		return this.http.post(url, {}, {headers:{skip:"true"}});
	}
}
