import { Injectable } from '@angular/core';
import {
	AngularFirestore,
	AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { USER } from '../shared/models/user';
import {
	EventCollectionI,
	EventCollectorData,
	LogCollectionI,
	events,
} from '../shared/models/event.model';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import packageJson from '../../../package.json';

@Injectable({
	providedIn: 'root',
})
export class EventCollector {
	user: USER;
	private mainCollect: AngularFirestoreCollection<EventCollectorData>;
	private eventCollect: AngularFirestoreCollection<EventCollectionI>;
	private logCollect: AngularFirestoreCollection<LogCollectionI>;

	constructor(private firestore: AngularFirestore) {
		if (localStorage.getItem('user')) {
			this.user = JSON.parse(atob(localStorage.getItem('user') || '') || '');
		}
		this.mainCollect = this.firestore.collection('traderFront');
		this.eventCollect = this.firestore.collection('event');
		this.logCollect = this.firestore.collection('log');
	}

	async event(data: any) {
		await this.eventCollect.add({
			appVersion: packageJson.version,
			plattform: 'Web',
			device: window.navigator.userAgent,
			environment: environment.ENV,
			timestamp: moment().valueOf(),
			type: data.type,
			module: data.module,
			class: data.class,
			method: data.method,
			deviceId: '',
			userId: this.user ? this.user.traderId : '',
			data: data.data,
			name: data.name,
		});
	}

	async log(data: any) {
		await this.logCollect.add({
			appVersion: packageJson.version,
			plattform: 'Web',
			device: window.navigator.userAgent,
			environment: environment.ENV,
			timestamp: moment().valueOf(),
			type: data.type,
			module: data.module,
			class: data.class,
			method: data.method,
			deviceId: '',
			userId: this.user ? this.user.traderId : '',
			data: data.data,
		});
	}
}
