<div class="modal-header" [ngClass]="{'mb-4': data.question}">
  <div class="question" *ngIf="data.question">
    <img src="assets/icons/question.png" alt="Pregunta" width="70" height="70">
  </div>
	<mat-icon class="modal-icon" [ngStyle]="iconStyles" [style.color]="color" *ngIf="(icon) && (icon != '') && !data.question">{{icon}}</mat-icon>
  <!--<button type="button" class="btn-close" aria-label="Close" (click)="closeModal(false)"> &#10006; </button>-->
</div>
<div class="modal-body" *ngIf="!data.justTitle">
	<h2 class="modal-title" [style.color]="color">{{ data.title }}</h2>
  <p class="modal-description" [innerHTML]="data.text"></p>
</div>
<div class="modal-body" *ngIf="data.justTitle">
	<h2 class="modal-title" [ngStyle]="{'font-size': data.titleSize}" [innerHTML]="data.title"></h2>
</div>
<div class="footer" *ngIf="data.footer">
  <p>{{data.textFooter}}</p>
</div>
<div class="modal-footer" [ngClass]="{'justify-content-center': !data.cancelButton, 'justify-content-around': data.cancelButton}">
  <button *ngIf="data.cancelButton" mat-stroked-button color="primary" class="button-export button-outlined" [ngClass]="{'button-question cancel': data.question}" (click)="closeModal(false)"> {{data.textCancelButton}} </button>
  <button *ngIf="data.confirmButton" mat-raised-button color="primary" class="button-export" [ngClass]="{'button-question accept': data.question}" (click)="closeModal(true)"> {{data.textConfirmButton}} </button>
</div>
