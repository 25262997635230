export interface DropdownCustom {
  id: number;
  name: string;
}

export interface FilterValue {
  id: number;
  values: any;
  filterChip: Chip;
}

export interface Chip {
  id: number;
  name: string;
  icon: string;
  type: TypeChip;
  last: boolean;
}

export enum TypeChip {
  IMAGE,
  ICON
}