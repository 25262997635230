
	<div class="file-modal-header">
		<div class="row">
			<div class="col-12 text-center">
				<div class="fw-bold mt-4">{{titleModal}}</div>
			</div>
		</div>
	</div>
	<div class="modal-body p-4">
		<div class="row">
			<div class="col-12 text-center mt-5">
				<img src="assets/files-icon/excel.svg" class="img-excel">
				<div class="file-name mt-3 mb-3">{{fileName}}</div>
				<span class="other-file" (click)="startOther()">Subir otro archivo</span>
			</div>
		</div>
		<hr>
		<div class="row mt-4 mb-5 justify-content-center">
			<div class="col-4 text-center">
				<span class="info-users">{{ textAceppted}}</span><br>
				<span class="fw-bold">{{acceptedUsers.length}}</span>
			</div>
			<div class="col-4 text-center">
				<span class="info-users">{{ textDenied}}</span><br>
				<span class="fw-bold">{{deniedUsers.length}}</span>
			</div>
			<div class="col-4 text-center" *ngIf="deniedUsers.length > 0 || avanceVentas">
				<button mat-stroked-button color="primary" class="border-r btn-nooutline btn-down" (click)="startError()">
					Descargar
					<mat-icon class="icon-down">file_download</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div *ngIf="deniedUsers.length === 0 || avanceVentas">
			<button mat-raised-button color="primary" class="text-orange border-r bg-transp" (click)="startClose()"> Cancelar </button>
			<button mat-raised-button color="primary" class="btn-w border-r ms-2" (click)="startAction()" [disabled]="invalidAction"> {{ titleButton }} </button>
		</div>
		<div *ngIf="deniedUsers.length > 0 && !avanceVentas">
			<button mat-raised-button color="primary" class="btn-w border-r ms-2" (click)="startClose()"> Cancelar </button>
		</div>
	</div>

