import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count'
})
export class CountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let newValue = value;
    if (value < 1000) { return value;}
    const suffix = ['', 'K', 'M'];
    let indexSufix = 0;
    while(newValue >= 1000) {
      newValue /= 1000;
      indexSufix++;
    }
    console.log(newValue)
    let precision = newValue < 10 
      ? newValue % 1 === 0 
        ? 1 
        : 2
      : newValue >= 10 && newValue < 100 
        ? 2 
        : 3
    let countPre = newValue.toPrecision(precision);

    return countPre + suffix[indexSufix];
  }

}
